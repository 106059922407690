<template>
  <div>
    <b-container>
      <b-row>
        <b-card style="margin-top:30px" class="col-md-6 mx-auto">
          <div class="title-3 mb-3">
            Login
          </div>
          <div id="first">
            <div class="myform form">
              <form
                action
                method="post"
                name="login"
                @submit.prevent="onSubmit(username, password)"
              >
                <div class="form-group">
                  <!-- <label for="exampleInputEmail1">username</label> -->
                  <input
                    id="username"
                    v-model.trim="username"
                    type="text"
                    name="username"
                    class="form-control"
                    aria-describedby="emailHelp"
                    placeholder="Enter Username"
                  >
                </div>
                <div class="form-group field-pwd">
                  <!-- <label for="exampleInputEmail1">Password</label> -->
                  <input
                    id="password"
                    v-model.trim="password"
                    :type="type"
                    name="password"
                    class="form-control"
                    aria-describedby="emailHelp"
                    placeholder="Enter Password"
                  >
                  <b-button
                    id="show-btn"
                    class="float-right show-btn"
                    variant="primary"
                    @click="togglePasswrod"
                  >
                    <font-awesome-icon
                      :icon="eyeIcontype"
                      style="width: 18px;"
                    />
                  </b-button>
                </div>
                <div class="form-group">
                  <p class="text-center">
                    By signing up you accept our
                    <a href="https://www.exemplar-education.com/terms-and-conditions/" target="_blank">Terms of use</a>
                  </p>
                  <p class="text-center pb-2">
                    Can't remember your password?
                    <b-link
                      href="#"
                      @click="showRecoveryModel"
                    >
                      Recover password
                    </b-link>
                  </p>
                </div>
                <div class="col-md-12 text-center">
                  <button
                    :disabled="
                      !username || !password || isAuthenticatePending
                    "
                    type="submit"
                    class="btn btn-block mybtn btn-primary tx-tfm"
                  >
                    Login
                    <div
                      v-if="isAuthenticatePending"
                      class="spinner-grow"
                    />
                  </button>
                </div>
              </form>
            </div>
          </div>
        </b-card>
      </b-row>
    </b-container>
    <SetPasswordModel
      :token="token"
      :show="showModel"
      :recover="recover"
      :close-model="closeModelFn"
    />
  </div>
</template>

<script>
import { mapActions, mapMutations, mapState } from 'vuex'
import SetPasswordModel from '@/components/common/SetPassword.vue'

export default {
  name: 'LoginComponent',
  components: {
    SetPasswordModel
  },
  data () {
    return {
      type: 'password',
      eyeIcontype: 'eye',
      username: undefined,
      password: undefined,
      error: undefined,
      token: null,
      showModel: null,
      recover: false
    }
  },
  computed: {
    ...mapState('auth', ['isAuthenticatePending'])
  },
  beforeMount () {
    if (this.$route.query.token) {
      this.token = this.$route.query.token
      this.showModel = true
    }
  },
  methods: {
    showRecoveryModel () {
      this.showModel = true
      this.recover = true
    },
    ...mapActions('auth', ['logout']),
    ...mapActions('misc', ['logEvent']),
    // ...mapActions('custom-logs', { logEvent: 'create' }),
    closeModelFn () {
      this.showModel = false
      this.recover = false
    },
    dismissError () {
      this.error = undefined
      this.clearAuthenticateError()
    },

    async onSubmit (username, password) {
      try {
        if (this.$store.state.auth.user) {
          await this.logout()
        }
        const response = await this.authenticate({
          strategy: 'local',
          username,
          password
        })

        const { user, payload } = this.$store.state.auth
        this.addUserData(user)
        this.$ability.update(payload.rules)
        if (response.accessToken && user && user.type) {
          if (user.lastAccessedDate) {
            this.$router.push('/')
          } else {
            this.showModel = true
          }
        }
      } catch (error) {
        if (error.code !== 500) {
          this.$bvToast.toast(error.message, {
            title: 'Warning',
            variant: 'warning',
            solid: true
          })
        } else {
          throw error
        }
        // console.log('Error logging in', error)
      }
    },
    togglePasswrod () {
      if (this.type === 'password') {
        this.type = 'text'
        this.eyeIcontype = 'eye-slash'
      } else {
        this.type = 'password'
        this.eyeIcontype = 'eye'
      }
    },
    ...mapMutations('auth', {
      clearAuthenticateError: 'clearAuthenticateError'
    }),
    ...mapMutations('misc', ['addUserData']),
    ...mapActions('auth', ['authenticate'])
  }
}
</script>

<style lang="scss" scoped>
.spinner-grow {
  max-height: 20px;
  max-width: 20px;
  padding: 0;
}
.form-control {
  position: relative;
  display: inline-block;
}
.show-btn {
  position: absolute;
  right:0;
  margin-right: 40px;
  background-color: transparent !important;
  color: #00a8c4;
  border: none;
  &:active {
    background-color: transparent !important;
  }
}

</style>
