<template>
  <b-modal
    ref="resetModel"
    :visible="show"
    cancel-disabled
    :no-close-on-esc="forceOpen"
    :no-close-on-backdrop="forceOpen"
    :hide-header="forceOpen"
    hide-footer
    @hide="onHideEvent"
  >
    <b-card v-if="recover" class="recover-acc">
      <div class="text-center title-3 mb-2">
        Recover my account
      </div>
      <b-form @submit.prevent="sendRecoveryEmail">
        <br>
        <b-form-input v-model="email" type="email" placeholder="Email address" required />
        <br>
        <b-button
          block
          type="submit"
          :disabled="isCreatePending"
          variant="primary"
          class="float-right"
        >
          Reset my account
        </b-button>
      </b-form>
    </b-card>
    <b-card v-else :title="title" class="text-center">
      <br>
      <form @submit.prevent="resetAccount">
        <br>
        <b-form-input
          v-model.trim="$v.pass.$model"
          type="password"
          :state="$v.pass.$dirty ? !$v.pass.$error : null"
          placeholder="Enter new password"
          aria-describedby="pass-feedback"
        />
        <b-form-invalid-feedback id="pass-feedback">
          {{ validationMsg($v.pass) }}
        </b-form-invalid-feedback>
        <br>
        <b-form-input
          v-model.trim="$v.confirmPass.$model"
          type="password"
          :state="$v.confirmPass.$dirty ? !$v.confirmPass.$error : null"
          placeholder="Re-enter new password"
          aria-describedby="confirmPass-feedback"
        />
        <b-form-invalid-feedback id="confirmPass-feedback">
          {{ validationMsg($v.confirmPass) }}
        </b-form-invalid-feedback>
        <br>
        <b-button :disabled="isPatchPending" type="submit" variant="primary" block>
          {{ buttonTitle }}
        </b-button>
      </form>
    </b-card>
  </b-modal>
</template>

<script>
import { mapActions, mapState } from 'vuex'
import { required, minLength, maxLength, sameAs } from 'vuelidate/lib/validators'
import { validationMessage } from 'vuelidate-messages'
import { validationMessageData } from '@/common'
export default {
  props: {
    token: {
      type: String,
      default: ''
    },
    recover: {
      type: Boolean,
      default: false
    },
    show: {
      type: Boolean,
      default: false
    },
    closeModel: {
      type: Function,
      default: null
    }
  },
  data () {
    return {
      title: '',
      buttonTitle: '',
      forceOpen: false,

      email: null,
      pass: null,
      confirmPass: null
    }
  },
  computed: {
    type () {
      return this.token ? 'reset' : !this.recover ? 'set' : null
    },
    ...mapState('account-recovery', ['isCreatePending', 'isPatchPending']),
    ...mapState('auth', ['user'])
  },
  watch: {
    show (val) {
      if (val) {
        if (this.recover) {
          this.forceOpen = false
        } else {
          this.forceOpen = true
        }
      }
    }
  },
  validations () {
    return {
      pass: {
        required,
        minLength: minLength(4),
        maxLength: maxLength(20)
      },
      confirmPass: {
        required,
        minLength: minLength(4),
        maxLength: maxLength(20),
        sameAsPassword: sameAs('pass')
      }
    }
  },
  beforeMount () {
    // console.log('TCL: mounted -> this.type', this.type)
    // console.log('TCL: mounted -> this.recover', this.recover)
    switch (this.type) {
      case 'reset':
        this.title = 'Reset your account password'
        this.forceOpen = true
        this.buttonTitle = 'Update password'
        break
      case 'set':
        this.title = 'Set your account password'
        this.forceOpen = true
        this.buttonTitle = 'Save password'
        break
    }
  },
  methods: {
    ...mapActions('account-recovery', {
      sendRecoveryEmailFn: 'create',
      resetAccountFn: 'patch'
    }),
    ...mapActions('users', {
      patchUser: 'patch'
    }),
    async resetAccount () {
      this.$v.$touch()
      if (this.$v.$invalid) {
        return
      }
      if (!this.pass || !this.confirmPass) {
        this.$bvToast.toast('Password cannot be empty', {
          title: 'Error',
          variant: 'danger',
          solid: true
        })
        return
      } else if (this.pass !== this.confirmPass) {
        this.$bvToast.toast('Passwords do not match', {
          title: 'Error',
          variant: 'danger',
          solid: true
        })
        return
      }
      this.forceOpen = false
      try {
        if (this.type === 'reset') {
          await this.resetAccountFn([
            null,
            {
              token: this.token,
              password: this.pass
            }
          ])
        } else if (this.type === 'set') {
          await this.patchUser([
            this.user._id,
            {
              password: this.pass,
              lastAccessedDate: new Date()
            }
          ])
        }

        this.$bvToast.toast(this.type === 'set' ? 'Password successfully set' : 'Password succesfully reset', {
          title: 'Success',
          variant: 'success',
          solid: true
        })
        this.pass = this.confirmPass = ''
        this.closeModel()
        if (this.type === 'set') {
          this.$router.push('/')
        }
      } catch (error) {
        if (error) {
          // console.log('TCL: errorssss', error.message)
          this.$bvToast.toast(error.message, {
            title: 'Warning',
            variant: 'warning',
            solid: true
          })
        } else {
          throw error
        }
      }
    },

    async sendRecoveryEmail () {
      if (!this.email) {
        this.$bvToast.toast('Email is required', {
          title: 'Error',
          variant: 'danger',
          solid: true
        })
        return
      }
      this.forceOpen = false
      try {
        await this.sendRecoveryEmailFn({ email: this.email })
        this.$bvToast.toast('Check your email for further details', {
          title: 'Success',
          variant: 'success',
          solid: true
        })
        this.email = ''
        this.closeModel()
      } catch (error) {
        // console.log('TCL: error', error)
        if (error.message === 'Email is not in our system') {
          this.$bvToast.toast(error.message, {
            title: 'Warning',
            variant: 'warning',
            solid: true
          })
          this.email = ''
          this.username = ''
        } else {
          throw error
        }
        this.closeModel()
        this.email = ''
      }
    },
    onHideEvent (e) {
      this.forceOpen ? e.preventDefault() : this.closeModel()
    },
    validationMsg: validationMessage(validationMessageData)
  }
}
</script>

<style lang="scss" scoped>
@import 'assets/css/settings.scss';
.recover-acc {
  .card-body {
    padding: 0 !important;
  }
}
h4 {
  font-size: 1.3rem;
  font-weight: 600;
  font-family: Arial;
  color: #00a8c4 !important;
  line-height: 1.3;
}
.card-title {
  margin-bottom: -1rem !important;
}
.invalid-feedback {
  text-align: left;
  padding-left: 5px;
}
</style>
