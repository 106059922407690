<template>
  <div>
    <LoginComponent />
  </div>
</template>

<script>
import LoginComponent from '@/components/Login'
export default {
  components: { LoginComponent }
}
</script>

<style lang="scss" scoped>
</style>
